import React, { useState, useEffect, useRef } from 'react'
import liveChatArrow from '../../assets/live_chat_arrow.png'
import { LiveInput } from '../../components/LiveInput'
import { useParams } from 'react-router-dom'
import { postLiveComment } from '../../../../services/post/postLiveComment'
import clsx from 'clsx'
import { useDuringContext } from './_Provider'
import liveNameBgOrange from '../../assets/live_name_bg_orange.png'
import liveNameBgPink from '../../assets/live_name_bg_pink.png'
import PropTypes from 'prop-types'
import _ from 'lodash'

const LiveComments = ({ normalPassportModal, canWorkWithFreePassport }) => {
  const msgList = useRef()
  const [items, setItems] = useState([])
  const [displayTopIcon, setDisplayTopIcon] = useState('hidden')
  const { channel_id: channelId } = useParams()
  const { liveItemsSocket, setAnimationItems } = useDuringContext()
  const [itemsExpensive, setItemsExpensive] = useState([])
  const [queueItems, setQueueItems] = useState([])

  const submitComment = async (msg) => {
    try {
      await postLiveComment({
        content: msg,
        livestream_id: channelId
      })
    } catch {}
  }

  const getContent = (item) => {
    let textData = item.user_name
    if (item.type === 'extra-time') {
      textData += `：${item.item_name}を使用しました`
    } else if (item.type === 'item') {
      textData += `：${item.item_name}をプレゼントしました`
    } else if (item.type === 'comment') {
      textData += `：${item.content}`
    } else if (item.type === 'joined-user') {
      textData += 'が参加しました'
    } else if (item.type === 'rejoined-user') {
      textData += 'が再度参加しました'
    }
    return textData
  }

  const bgItem = (type) => {
    if (type === 'extra-time') {
      return 'bg-time-item'
    } else if (type === 'item') {
      return 'bg-normal-item'
    } else if (type === 'comment') {
      return 'bg-comment'
    } else if (['joined-user', 'rejoined-user'].includes(type)) {
      return 'bg-joined-user'
    }
  }

  useEffect(() => {
    if (liveItemsSocket.item) {
      setItems([...items, liveItemsSocket.item])
    }
  }, [liveItemsSocket.item])

  useEffect(() => {
    if (!liveItemsSocket.expensiveItem) return
    if (_.isEmpty(liveItemsSocket.expensiveItem)) {
      setItemsExpensive([...itemsExpensive.slice(1)])
      setAnimationItems([...itemsExpensive.slice(1)])
    } else {
      if (items.length === 3) {
        setQueueItems([...queueItems, liveItemsSocket.expensiveItem])
      } else {
        setItemsExpensive([...itemsExpensive, liveItemsSocket.expensiveItem])
        setAnimationItems([...itemsExpensive, liveItemsSocket.expensiveItem])
        if (!liveItemsSocket.expensiveItem.animation_url.endsWith('.mp4')) {
          removeFirstItem()
        }
      }
    }
  }, [liveItemsSocket.expensiveItem])

  useEffect(() => {
    if (items.length < 3 && queueItems.length > 0) {
      setItemsExpensive([...itemsExpensive, queueItems[0]])
      setAnimationItems([...itemsExpensive, queueItems[0]])
      if (!queueItems[0].animation_url.endsWith('.mp4')) {
        removeFirstItem()
      }
      setQueueItems([...queueItems.slice(1)])
    }
  }, [items])

  const removeFirstItem = () => {
    setTimeout(() => {
      liveItemsSocket.setExpensiveItem({})
    }, 9000)
  }

  const getContentExpensiveItem = (item) => {
    return `${item.user_name}：${item.item_name}`
  }

  useEffect(() => {
    if (msgList?.current) {
      msgList.current.scrollTop = msgList.current.scrollHeight
      if (msgList.current.scrollHeight > msgList.current.clientHeight) {
        setDisplayTopIcon('visible')
      }
    }
  }, [items])

  return (
    <>
      <img
        src={liveChatArrow}
        className="relative mx-auto live-arrow-top"
        style={{ visibility: displayTopIcon }}
      />

      {/* @ts-expect-error */}
      <div
        className="absolute text-left w-[75%] max-h-[45vh] overflow-y-auto hidden-scroll flex flex-col space-y-2"
        id="box-item"
        ref={msgList}
        style={{ bottom: '82px' }}
      >
        {items.map((item, index) => (
          <>
            {item.type === 'expensive-item' ? (
              <div key={index} className="relative w-[100%]">
                <img
                  src={item.is_firework ? liveNameBgOrange : liveNameBgPink}
                  className="w-[100%] h-[81px]"
                />
                <span className="absolute top-[50%] translate-y-[-50%] left-[16px] w-[100%]">
                  <div style={{ position: 'relative', width: '70%', minHeight: '20px' }}>
                    <p
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        fontWeight: 'bolder',
                        textShadow: '1px -1px #ef8b42'
                      }}
                      className="font-hiragino text-[12px] w-full"
                    >
                      {getContentExpensiveItem(item)}
                    </p>
                  </div>
                </span>
                <img
                  src={item.image_url}
                  className="absolute top-[44%] translate-y-[-55%] right-[-4px] w-[80px] h-[80px]"
                />
              </div>
            ) : (
              <div
                key={index}
                className={clsx(
                  'relative rounded-[6px] p-[8px] text-white font-hiragino text-[10px] text-left min-h-[75px] flex items-center break-all',
                  index === items.length - 1 && 'animation-fade-in',
                  `${bgItem(item.type)}`
                )}
              >
                <span>{getContent(item)}</span>
                {item?.image_url && (
                  <img src={item?.image_url || ''} className="absolute right-0 w-[40px] h-[50px]" />
                )}
              </div>
            )}
          </>
        ))}
      </div>

      <LiveInput
        normalPassportModal={normalPassportModal}
        canWorkWithFreePassport={canWorkWithFreePassport}
        onSend={(msg) => submitComment(msg)}
      />
    </>
  )
}

LiveComments.propTypes = {
  normalPassportModal: PropTypes.bool,
  canWorkWithFreePassport: PropTypes.bool
}

export { LiveComments }
